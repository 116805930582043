<!-- 店铺认领 -->
<template>
  <div class="do-page-acquisition-signal">
    <base-nav-bar @click-left="$router.go(-1)" left-arrow title="环境信号采集">
    </base-nav-bar>
    <base-bscroll
      :safeareaNavbar="true"
      :options="{
        bounce: true,
        nestedScroll: {
          groupId: 'vertical-nested-scroll', // groupId is a string or number
        },
      }"
    >
      <div class="do-page-acquisition-signal-content">
        <!-- 开始状态，选择扫描/跳过 -->
        <div v-if="stepState === 1">
          <div class="acquisition-wrap">
            <img :src="require('@/assets/images/my/scan-init.png')" />
          </div>
          <div class="acquisition-tip">
            <div>信标采集需在店铺内的中心位置，静置 60 秒左右</div>
            <div>采集期间请勿移动</div>
          </div>

          <div class="start-btn">
            <van-button @click="startAcquisition" type="default"
              >开始采集</van-button
            >
          </div>
          <div @click="submit('skip')" class="finish-btn">跳过，新增完成</div>
        </div>
        <!-- 扫描状态 -->
        <div v-if="stepState === 2">
          <div class="acquisition-wrap">
            <img :src="require('@/assets/images/my/scan.gif')" />
          </div>
          <div class="acquisition-tip">
            <div>请在店铺内的中心位置静置，请勿移动</div>
          </div>
          <div class="time-btn">
            <van-button type="default"
              >采集中，请静置
              <van-count-down
                ref="countDown"
                millisecond
                :time="15000"
                :auto-start="false"
                format="ss"
                @finish="onTimeFinish"
              />
              秒</van-button
            >
          </div>
        </div>
        <!-- 完成状态 -->
        <div v-if="stepState === 3">
          <div class="acquisition-succeed-wrap">
            <van-icon name="checked" size="2rem" color="#07c160" />
          </div>
          <div class="stepState-sced">
            <div>信息采集完成</div>
          </div>
          <!-- wifi列表 -->
          <div class="wifi-list-wrap">
            <span class="tip"> 请在下列表中勾选店铺的 WiFi 名称 </span>
            <div class="scroll-wrap">
              <van-empty
                v-if="wiflList.length === 0"
                image="network"
                description="未扫描到wifi，请输入wifi名称"
              />
              <base-bscroll
                v-else
                class="acquisition-succeed-wrap-scroll"
                :options="{
                  bounce: true,
                  nestedScroll: {
                    groupId: 'vertical-nested-scroll', // groupId is a string or number
                  },
                }"
              >
                <div>
                  <van-checkbox-group v-model="checked">
                    <van-cell-group inset>
                      <van-cell
                        v-for="(item, index) in wiflList"
                        clickable
                        :key="item"
                        :title="` ${item}`"
                        @click="toggle(index)"
                      >
                        <template #right-icon>
                          <van-checkbox
                            :name="item"
                            :ref="(el) => (checkboxRefs[index] = el)"
                            @click.stop
                          />
                        </template>
                      </van-cell>
                    </van-cell-group>
                  </van-checkbox-group>
                </div>
              </base-bscroll>
            </div>
          </div>
          <!-- 输入wifi -->
          <div class="wifi-field-wrap">
            <span class="tip"> 上列表中没有店铺的 WiFi 名称 </span>
            <van-field
              v-model.trim="inputWifi"
              class="wifi-field"
              placeholder="输入店铺的 WiFi 名称"
            />
          </div>
          <div class="submit-btn">
            <van-button
              :loading="loading"
              :class="{ 'curr-btn': submitCurrBol }"
              @click="submit"
              type="default"
              >完 成</van-button
            >
          </div>
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { ref, nextTick, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { sendConfirmShops } from "@/axios/user";
import { Toast } from "vant";

const stepState = ref(1);
const countDown = ref();
const router = useRouter();
const route = useRoute();
const checked = ref([]);
const checkboxRefs = ref([]);
const wiflList = ref([]);
const inputWifi = ref("");
const loading = ref(false);

// 提交按钮高亮
const submitCurrBol = computed(() => {
  return inputWifi.value.length || checked.value.length;
});

function startAcquisition() {
  stepState.value = 2;
  nextTick(() => {
    countDown.value.start();
    loadWifiInfo();
  });
}

const toggle = (index) => {
  console.log(index);
  checkboxRefs.value[index].toggle();
};

function onTimeFinish() {
  stepState.value = 3;
}

// 获取wifi列表
function loadWifiInfo() {
  if (!window.plus) return;
  const plus = window.plus;
  var MainActivity = plus.android.runtimeMainActivity();
  // 上下文
  var Context = plus.android.importClass("android.content.Context");
  // 导入WIFI管理 和 WIFI 信息 的class
  plus.android.importClass("android.net.wifi.WifiManager");
  plus.android.importClass("android.net.wifi.WifiInfo");
  plus.android.importClass("android.net.wifi.ScanResult");
  plus.android.importClass("java.util.ArrayList");
  // 获取 WIFI 管理实例
  var wifiManager = MainActivity.getSystemService(Context.WIFI_SERVICE);
  wifiManager.setWifiEnabled(true); //打开wifi,false为关闭
  wifiManager.startScan(); //开始扫描
  var resultList = wifiManager.getScanResults();
  var len = resultList.size();
  const wifiNameList = [];
  for (var i = 0; i < len; i++) {
    const name = resultList.get(i).plusGetAttribute("SSID");
    if (name && !wifiNameList.includes(name)) {
      wifiNameList.push(name);
    }
  }
  wiflList.value = wifiNameList;
  console.log("wifiNameList", wifiNameList);
  return wifiNameList;
}

async function submit() {
  if (stepState.value === 3 && !submitCurrBol.value) {
    return false;
  }
  try {
    const query = route.query;
    if (query && query.data) {
      const queryData = JSON.parse(query.data);
      const poi_info = { wifi_list: checked.value };
      if (inputWifi.value) {
        poi_info.wifi_list = poi_info.wifi_list.concat(inputWifi.value);
      }
      checked.value.concat();
      loading.value = true;
      const { data, code } = await sendConfirmShops({
        ...queryData,
        poi_info,
        type: 2,
      });
      if (code === 0 && data) {
        Toast.success("新增成功");
        // 暂无数据
        router.replace("/my/store-management");
      }
      setTimeout(() => {
        loading.value = false;
      }, 300);
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "服务端错误 ",
      });
    }
  }
}
</script>

<style lang="scss">
.do-page-acquisition-signal {
  &-content {
    padding: 0.24rem 0 0rem 0;
    // padding-bottom: calc(0.24rem + constant(safe-area-inset-bottom));
    // padding-bottom: calc(0.24rem + env(safe-area-inset-bottom));

    // wifi 列表
    .wifi-list-wrap {
      margin: 0.32rem 0.24rem 0.12rem 0.24rem;
      .scroll-wrap {
        display: flex;
        overflow: hidden;
        justify-content: center;
        background: #ffffff;
        border-radius: 0.16rem;
      }
      .van-cell-group,
      .van-cell--clickable {
        background: transparent !important;
      }
      .tip {
        display: flex;
        color: rgba(2, 4, 15, 0.65);
        font-size: 0.24rem;
        font-weight: 400;
        margin-bottom: 0.12rem;
      }
      .van-cell-group {
        margin: 0 !important;
      }
      .van-empty {
        padding: 0.32rem 0;
      }
    }

    .acquisition-wrap {
      img {
        width: 4rem;
        height: 4rem;
      }
    }

    // 输入wifi
    .wifi-field-wrap {
      display: flex;
      flex-direction: column;
      padding: 0.32rem 0.24rem 0.12rem 0.24rem;
      .wifi-field {
        border-radius: 0.16rem;
      }
      .tip {
        display: flex;
        color: rgba(2, 4, 15, 0.65);
        font-size: 0.24rem;
        font-weight: 400;
        margin-bottom: 0.12rem;
      }
    }

    .acquisition-wrap,
    .acquisition-succeed-wrap {
      height: 4rem;
      margin: 0.32rem 0.32rem 0.32rem 0.32rem;
      display: flex;
      justify-content: center;
    }
    .acquisition-succeed-wrap {
      height: 2rem;
      margin-bottom: 0;
      &-scroll {
        width: 100%;
        max-height: 3.6rem;
      }
    }

    .stepState-sced {
      font-size: 0.32rem;
      font-weight: bold;
      text-align: center;
    }

    .van-count-down {
      display: inline-block;
      font-size: 0.32rem;
      font-weight: 500;
      color: #ffffff;
    }
    .acquisition-tip {
      text-align: center;
      margin-top: 0.2rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.45);
    }

    .start-btn,
    .submit-btn {
      display: flex;
      margin-top: 0.6rem;
      button {
        width: 100%;
        margin: 0 0.24rem;
        background: #0057ff;
        border-radius: 0.16rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .submit-btn {
      button {
        background: #c0c0c3;
      }
    }

    .curr-btn {
      background: #0057ff !important;
    }

    .time-btn {
      display: flex;
      margin-top: 0.6rem;
      button {
        width: 100%;
        margin: 0 0.24rem;
        background: #c0c0c3;
        border-radius: 0.16rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .finish-btn {
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.85);
      text-align: center;
      margin-top: 0.4rem;
      text-decoration: underline;
    }
  }
}
</style>
